const TerminiEs = () => {
    return (
        <>
            <section className="termini">
                <h2>Laborrenkontiĝoj</h2>
                <p>
                    Aplikoj retpoŝte<b> ankica.konstelacije@gmail.com </b>
                </p>
            </section>
        </>
    );
};

export default TerminiEs;
