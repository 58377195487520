import './index.css';

const Footer = () => {
    return (
        <div className="footer-container">
            <p className="footer-content">Obiteljske konstelacije</p>
            <p className="footer-content">ankica.konstelacije@gmail.com</p>
        </div>
    );
};

export default Footer;
