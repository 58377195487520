import './index.css';

const FooterEs = () => {
    return (
        <div className="footer-container">
            <p className="footer-content">Familiaj konstelacioj</p>
            <p className="footer-content">ankica.konstelacije@gmail.com</p>
        </div>
    );
};

export default FooterEs;
